import React, {useState} from "react";


function UserEditModal( {listRoles, userInfo}) {
    
    // const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('');
    const [nameEdit, setNameEdit] = useState('');
    const [lastname, setLastname] = useState('');
    const [role, setRole] = useState('');

    const doSave = async (obj) => {
        console.log(userInfo)
    }

    return (
        <div className="modal fade" id="editModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Editar</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <form>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Nombre</label>
                                    <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Nombre del usuario"                                 
                                        onChange={(e)=> setNameEdit(e.target.value)}
                                        // value={userInfo.attributes.name}
                                    />
                                </div>
                               {/*  <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Apellido</label>
                                    <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Apellido del usuario" 
                                        onChange={(e)=> setLastname(e.target.value)}
                                        value={userInfo.attributes.last_name}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-email">Usuario</label>
                                    <input type="text" id="basic-default-email" className="form-control" placeholder="Ingrese email del usuario" aria-label="john.doe" aria-describedby="basic-default-email2" 
                                        onChange={(e)=> setEmail(e.target.value)}
                                        value={userInfo.attributes.email}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlSelect1" className="form-label">Perfil</label>
                                    <select className="form-select" id="roleControlSelect" aria-label=""  onChange={(e)=> setRole(e.target.value)} value={userInfo.included.roles[0].id}>
                                        {listRoles.map( (role) => 
                                            <option value={role.id}>{role.attributes.name}</option> )
                                        }
                                    </select> 
                                </div> */}
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"> Cerrar </button>
                        <button type="button" className="btn btn-info" onClick={() => {  doSave(userInfo) }}>Guardar</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserEditModal;