import api from "../apiapp";


const roleService = {
    getRoles: async function () {

        const response = await api.request({
          url: "/roles",
          method: "GET",
        })

        return response.data
    },

}
  
export default roleService;