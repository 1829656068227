


function Footer() {
    return (  
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">
                    {/* © */}
                    {/* , made with ❤️ by  */}
                    {/* <a href="https://globemind.com/" target="_blank" className="footer-link fw-bolder" style={{marginLeft: 10}}> GlobeMind Software Distillery</a> */}
                </div>
                <div style={{fontSize: 'smaller', marginRight: '15px'}}>
                    ©
                    <a href="https://globemind.com/" target="_blank" className="footer-link fw-bolder" style={{marginLeft: 10}}> GlobeMind Software Distillery</a>
                </div>
            </div>
        </footer>

    );
}

export default Footer;

