import React, {useState} from "react";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
import userService from "../../services/userService";
import {useNavigate} from "react-router-dom"
// import $ from "jquery";


function UserNewModal( {listRoles}) {

    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    // const [listRoles, setListRoles] = useState([])
    const [newemail, setNewemail] = useState('');
    const [newname, setNewname] = useState('');
    const [newlastname, setNewlastname] = useState('');
    const [newrole, setNewrole] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [newrepassword, setNewrepassword] = useState('');



    const doCreate = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                const model = {
                    name: newname,
                    last_name: newlastname,
                    email: newemail,
                    password: newpassword,
                    password_confirmation: newrepassword,
                    role: newrole,
                    change_password: 0,
                    password_generate: 0,
                }
                // const response = await userService.setUsers(model)
                toast.success('Usuario creado!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function validateNewForm (){
        let rta = true;
        if(newname === '' || newname === undefined  || newname === null){ return false; } 
        if(newlastname === '' || newlastname === undefined  || newlastname === null){ return false; } 
        if(newemail === '' || newemail === undefined  || newemail === null){ return false; } 
        if(newrole === '' || newrole === undefined  || newrole === null){ return false; } 
        if(newpassword === '' || newpassword === undefined  || newpassword === null){ return false; } 
        if(newrepassword === '' || newrepassword === undefined  || newrepassword === null){ return false; } 
        if(newrepassword !== newpassword){ return false; }
        return rta;
    }

    return (
        <div className="modal fade" id="newModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Nuevo </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <form>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Nombre <span style={{color:'red'}}>*</span></label>
                                    <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Nombre del usuario" onChange={(e)=> setNewname(e.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Apellido <span style={{color:'red'}}>*</span></label>
                                    <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Apellido del usuario" onChange={(e)=> setNewlastname(e.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-email">Email <span style={{color:'red'}}>*</span></label>
                                    <input type="email" id="basic-default-email" className="form-control" placeholder="Ingrese email del usuario" aria-label="john.doe" aria-describedby="basic-default-email2" 
                                        onChange={(e)=> setNewemail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Contraseña <span style={{color:'red'}}>*</span></label>
                                    <input type="password" className="form-control" id="basic-default-fullname" placeholder="Ingrese Contraseña" onChange={(e)=> setNewpassword(e.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Confirme Contraseña <span style={{color:'red'}}>*</span></label>
                                    <input type="password" className="form-control" id="basic-default-fullname" placeholder="Confirme Contraseña" onChange={(e)=> setNewrepassword(e.target.value)}/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlSelect1" className="form-label">Perfil <span style={{color:'red'}}>*</span></label>
                                    <select className="form-select" id="roleControlSelect" aria-label=""  onChange={(e)=> setNewrole(e.target.value)}>
                                    <option selected value={''}>Seleccione una Opción</option>
                                        {listRoles.map( (role) => 
                                            <option value={role.name}>{role.attributes.name}</option> )
                                        }
                                    </select> 
                                </div>
                                <div className="mb-3">
                                    <small style={{color:'red'}}>* obligatorios</small>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"> Cerrar </button>
                        
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-info" onClick={doCreate}>Guardar</button>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UserNewModal;