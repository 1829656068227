import React, {useState} from "react";
import Swal from "sweetalert2";
import axios from 'axios'
import authService from "../../services/authService";
import {useNavigate} from "react-router-dom"
import { Link } from "react-router-dom";

function Recovery() {
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('');
  
    const doForgot = async (e) => {
      // e.preventDefault();
      setSending(true)
      if(email === ''){
        Swal.fire({
          title: 'Ooops?',
          text: 'Debe completar el email para poder avanzar!',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#f13939'
        })
      }else{
        
        try {
          const response = await authService.recovery(email)
          // console.log(response);
          Swal.fire({
            title: 'Perfecto!',
            text: 'Te enviamos un mail con la clave temporal. Si no lo recibiste, te olvides de revisar la carpeta de SPAM!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#97e36b'
          }).then(function(){
            navigate("/")
          })
          
        } catch (e) {
          
          if (axios.isAxiosError(e)) {
            const statusCode = e.response?.status
            const error = e.response?.data.errors;
            let message = '';
            
            if(statusCode && statusCode !== 401){
              message = error[0].detail[0]  
            }else{
              message = error[0].detail
            }
            Swal.fire({
              title: 'ERROR',
              text: message,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#f13939'
            })
          }
        }
      }
      setSending(false)
    }

    return (
        <div>
          <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
              <div className="authentication-inner">
                {/* Register */}
                <div className="card">
                  <div className="card-body">
                    {/* Logo */}
                    <div className="app-brand justify-content-center">
                      <a href="/" className="app-brand-link gap-2">
                        <img src="alchemy-logo.png" alt="alchemy valores logo" className="img-fluid" style={{width: '100%'}}/>
                      </a>
                    </div>
                    {/* /Logo */}
                    <h4 className="mb-2">Olvide Contraseña? 🔒</h4>
                    <p className="mb-4">Ingresa tu email, te enviaremos las indicaciones para acceder a tu cuenta.</p>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="text" className="form-control" id="email" name="email-username" placeholder="Ingrese email" autoFocus onChange={(e)=> setEmail(e.target.value)}/>
                      </div>
                      <div className="mb-3">
                      { sending ? 
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      : 
                        <button className="btn btn-info d-grid w-100" type="button" onClick={doForgot}>Recuperar Contraseña</button> 
                      } 
                      </div>
                      <div className="text-center">
                        <Link to='/' className="d-flex align-items-center justify-content-center">
                            <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm" />
                            Volver
                        </Link>
                    </div>

                  </div>
                </div>
                {/* /Register */}
              </div>
            </div>
          </div>
          <footer style={{textAlign: 'right', marginRight: 20,fontSize: 'small', marginTop: '-25px'}}>
            Power by <a href="https://globemind.com/" target="_blank" style={{color: '#396bad'}}>GlobeMind</a>
          </footer>
        </div>        
  
      );
}

  
export default Recovery;