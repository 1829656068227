import api from "../apiapp";


const documentService = {
    getDocumentSearchPagination: async function (purl) {
      const response = await api.request({
        url: purl,
        method: "GET",
      })
      
      return response.data
    },
    getDocumentSearch: async function (status) {
      var filters = '';
      if(status !== ""){ filters += '&filter[status]='+status; }
        
      const response = await api.request({
        url: "sworn-statements?page[size]=30&include=swornStatementType"+filters,
        method: "GET",
      })
    
      return response.data
    },
    putDocumentStatus: async function (id) {
      const response = await api.request({
        url: "sworn-statements/change-status/"+id,
        method: "POST",
        data: {}
      })
    
      return response.data
    },

}
  
export default documentService;