import React, {useState, useEffect} from "react";
import Autocomplete from '@mui/joy/Autocomplete';
import { toast } from 'react-toastify';


function SendDocumentModal( {listStatement, listAccounts}) {
    
    const [sending, setSending] = useState(false)
    const [statement, setStatement] = useState();
    const [comitente, setComitente] = useState('');
    const [email, setEmail] = useState('');
    const [fullname, setFullname] = useState('');
    
    const newListAccounts = []
    listAccounts.forEach(data => newListAccounts.push(
        {
            id: data.id,
            account: data.attributes.account,
            name: data.attributes.name,
            emails: data.attributes.emails,
        }
    ))

    const selectComitente = async (obj) => {
        setComitente(obj.account)
        setEmail(obj.emails)
        setFullname(obj.name)
    } 

    function validateNewForm (){
        let rta = true;
        if(comitente === '' || comitente === undefined  || comitente === null){ return false; } 
        if(email === '' || email === undefined  || email === null){ return false; } 
        if(fullname === '' || fullname === undefined  || fullname === null){ return false; } 
        if(statement === '' || statement === undefined  || statement === null){ return false; } 
        return rta;
    }

    const doSave = async () => {
        setSending(true)
        if(validateNewForm()){
            let model = {
                account: comitente,
                name: fullname,
                email: email,
                note: statement
            }
            console.log("Save:",model)
            toast.success('Notifiación enviada!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            setSending(false)
        }else{
            setSending(false)
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    } 

    return (
        <div className="modal fade" id="sendModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Nuevo Envio</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <form>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-notification">Notificación <span style={{color:'red'}}>*</span></label>
                                    <select className="form-select" id="basic-default-notification" onChange={(e)=> setStatement(e.target.value)}>
                                        <option value="" key="null">Seleccione una Notificación</option>
                                        {listStatement.filter(st => st.attributes.active).map( (state) => 
                                            <option value={state.id} key={state.id}>{state.attributes.display_name}</option> )
                                        }
                                    </select> 
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-comitente">Seleccione Comitente <span style={{color:'red'}}>*</span></label>
                                    <Autocomplete 
                                        options={newListAccounts} 
                                        getOptionLabel={option => option.name +' - '+ option.account }
                                        slotProps={{
                                            listbox: {
                                              sx: (theme) => ({
                                                zIndex: theme.vars.zIndex.modal
                                              })
                                            }
                                        }}
                                        // onChange={(option)=> selectComitente(option)}
                                        placeholder="Seleccione un Comitente" 
                                        onChange={(event, newValue) => { selectComitente(newValue); }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-comitente-selected">Comitente</label>
                                    <input type="text" className="form-control" id="basic-default-comitente-selected" placeholder="" disabled={true}                                 
                                        value={comitente}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-fullname">Nombre Completo</label>
                                    <input type="text" className="form-control" id="basic-default-fullname" placeholder="" disabled={true} 
                                        value={fullname}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-default-email">Email <span style={{color:'red'}}>*</span></label>
                                    <input type="text" className="form-control" id="basic-default-email" placeholder=""                               
                                        value={email}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"> Cerrar </button>
                        <button type="button" className="btn btn-info" 
                            onClick={() => {  doSave() }}
                            disabled={sending}
                        >Guardar</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SendDocumentModal;