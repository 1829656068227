import api from "../apiapp";
// import apinew from "../apinew";


const fundService = {
    getFundSearch: async function (model) {
      var filters = '';
      if(model.name !== ""){ filters += '&filter[name]='+model.name; }
      const response = await api.request({
          url: 'funds?include=subscriptionTerm,rescueTerm,currency&sort=name&page[size]=100&filter[active]=1'+filters,
          method: "GET",
      })
      
      return response.data
    },
    getFundSearchPagination: async function (purl) {
      const response = await api.request({
        url: purl,
        method: "GET",
      })
      
      return response.data
    },
    deleteFund: async function (id) {
      const response = await api.request({
        url: 'funds/'+id,
        method: "DELETE",
      })
      
      return response.data
    },
    setFund: async function (model) {
      const response = await api.request({
        url: 'funds/',
        data: model,
        method: "POST",
      })
      
      return response.data
    },
    putFund: async function (model, id) {
      const response = await api.request({
        url: 'funds/'+id,
        data: model,
        method: "PUT",
      })
      
      return response.data
    },
    getFundOperationSearch: async function (model) {
      var filters = '';
      if(model.dateFrom !== '' && model.dateFrom !== ""){ filters += '&filter[from]='+model.dateFrom; }
      if(model.dateTo !== '' && model.dateTo !== ""){ filters += '&filter[to]='+model.dateTo; }
      if(model.account !== '' && model.account !== null){ filters += '&filter[account]='+model.account; }
      if(model.status !== '' && model.status !== null){ filters += '&filter[state]='+model.status; }
      if(model.operationType !== '' && model.operationType !== null){ filters += '&filter[operationType]='+model.operationType; }
      if(model.acdi !== '' && model.acdi !== null){ filters += '&filter[isAcdi]='+model.acdi; }

      const response = await api.request({
        url: 'fund-operations?include=fundOperationMailLogs,accountModel,fundOperationStatus,fund,user,operationLogs,settlementTerm,operationType,currency,operationState,investmentMode,blockedBy&sort=date&page[size]=50'+filters,
        method: "GET",
      })
      
      return response.data
    },
    putFundPaymentStatus: async function (id, status) {
      const response = await api.request({
        url: 'fund-operations/'+id+'/update-payment-status/',
        data: {
          fund_payment_status_id: status
        },
        method: "PUT",
      })
      return response.data
    },
    getFundOperationPaymentSearch: async function (model) {
      var filters = '';
      if(model.dateFrom !== '' && model.dateFrom !== ""){ filters += '&filter[from]='+model.dateFrom; }
      if(model.status !== '' && model.status !== null){ filters += '&filter[state]='+model.status; }
      
      const response = await api.request({
        url: 'fund-operations?include=fundOperationMailLogs,accountModel,fundOperationStatus,fund,user,operationLogs,settlementTerm,operationType,currency,operationState,investmentMode,blockedBy&sort=date&page[size]=50'+filters,
        method: "GET",
      })
      
      return response.data
    },
    postFundOperationNoPayment: async function (model) {
      const response = await api.request({
        url: 'fund-operations/reports/without-payment?include=fundOperationMailLogs,accountModel,fundOperationStatus,fund,user,operationLogs,settlementTerm,operationType,currency,operationState,investmentMode,blockedBy',
        data: model,
        method: "POST",
      })
      
      return response.data
    },
    /* getFundOperationPaymements: async function () {
      const response = await apinew.request({
        url: 'fund-operations/',
        method: "GET",
      })
      console.log("Nico", response)
      return response.data
    }, */
 
}
  
export default fundService;
