import api from "../apiapp";


const accountService = {
    getAccountAutocomplete: async function () {
      const response = await api.request({
        url: 'accounts?sort=name&page[size]=100000&filter[status]=1',
        method: "GET",
      })
      
      return response.data
    },
 

}
  
export default accountService;