import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import { toast } from 'react-toastify';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import fundService from "../../services/fundService";
import ModalViewFund from "../reports/modals/modalViewFund"
import Badge from '@mui/joy/Badge';
import Grid from '@mui/joy/Grid';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';


function Home() {
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [fundSelected, setFundSelected] = useState('');
    const [pendingListFunds, setPendingListFunds] = useState([])
    // const [listFundsMeta, setListFundsMeta] = useState([]);
    const [todayListFunds, setTodayListFunds] = useState([])
    const [todayListFundsMeta, setTodayListFundsMeta] = useState([]);

    // Search Form
    let defaultDate = new Date().toISOString().slice(0,10);
    // let defaultDate = '';
    const [searchModel, setSearchModel] = useState({
        status: '',
        dateFrom: defaultDate,
    })
    
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setSending(true)
            let today = moment().format("YYYY-MM-DD")
            const response = await fundService.postFundOperationNoPayment({ "to": defaultDate})
            // const response = await fundService.postFundOperationNoPayment({ "to": "2024-04-30"})
            let todayFunds = response.data.filter(obj => {
                // if(moment(obj.attributes.date).format("YYYY-MM-DD") != today) return false;
                if(moment(obj.attributes.settlement_date).format("YYYY-MM-DD") != today) return false;
                if(!obj.attributes.is_acdi) return false;
                if(obj.attributes.operation_type_id != 4) return false;
                return true
            });
            setTodayListFunds(todayFunds)
            setTodayListFundsMeta(response.meta)

            let pendingFunds = response.data.filter(obj => {
                if(moment(obj.attributes.settlement_date).format("YYYY-MM-DD") >= today) return false;
                if(!obj.attributes.is_acdi) return false;
                if(obj.attributes.operation_type_id != 4) return false;
                return true
            });
            setPendingListFunds(pendingFunds)

            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doView = async (obj) => {
        if(obj !== null){
            setFundSelected(obj)
            setModalViewOpen(true)
        }
    }

    const doPay = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere pagar la operación?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Pagar',
            confirmButtonColor: '#71dd37',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await fundService.putFundPaymentStatus(obj.id, 2)
                    toast.success('Pago Actualizado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    const doCancel = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere anular el pago de esta operación?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Anular',
            confirmButtonColor: '#f13939',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await fundService.putFundPaymentStatus(obj.id, 3)
                    toast.success('Pago Anulado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row mb-3">
                <div className="col">
                    <h2>Inicio</h2>
                </div>
            </div>
            
            
            <Tabs aria-label="Basic tabs" defaultValue={0} size="lg" >
                <TabList>
                    <Tab color="warning">
                        <Badge badgeContent={todayListFunds.length} color="success" badgeInset="0 -12px 0 0">Pendientes T0</Badge>
                    </Tab>
                    <Tab color="warning">
                        <Badge badgeContent={pendingListFunds.length} color="danger" badgeInset="0 -12px 0 0">Pendientes de Pago</Badge>
                    </Tab>
                </TabList>
                <TabPanel value={0}>
                    <Grid xs={12}>
                        <div className="card h-100">
                            <div className="card-body p-0">
                                <div className="table-responsive" style={{"min-height": "300px"}}>
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr className="text-nowrap">
                                                <th>#</th>
                                                <th>Comitente</th>
                                                <th>Fecha Operación</th>
                                                <th>Fecha Pago</th>
                                                <th>Moneda</th>
                                                <th className="text-end">Monto</th>
                                                <th className="text-center">Estado</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {todayListFunds.map(fund => {
                                                return (
                                                    <tr key={fund.id}>  
                                                        <td><span className="badge bg-label-dark">{fund.id}</span></td>
                                                        <td>
                                                            <span className="fw-medium">
                                                                {fund.included.accountModel.name.length > 45 ?
                                                                    `${fund.included.accountModel.name.substring(0, 45)}...` : fund.included.accountModel.name
                                                                }
                                                            </span>
                                                            <small class="text-muted d-block mb-1">
                                                                <span className="badge bg-label-primary me-1">{fund.attributes.account}</span>
                                                            </small>
                                                        </td>
                                                        <td>{moment(fund.attributes.date).format('DD MMM YYYY')}</td>
                                                        <td>{moment(fund.attributes.settlement_date).format('DD MMM YYYY')}</td>
                                                        <td>
                                                            {fund.included.currency.id === 1 && <span className="badge bg-label-secondary me-1">{fund.included.currency.name}</span>}
                                                            {fund.included.currency.id === 2 && <span className="badge bg-label-success me-1">{fund.included.currency.name}</span>}
                                                            {fund.included.currency.id === 3 && <span className="badge bg-label-primary me-1">{fund.included.currency.name}</span>}
                                                        </td>
                                                        <td className="text-end"> 
                                                            <span class="text-muted">{fund.included.currency.symbol} </span>
                                                            <NumericFormat 
                                                                displayType="text" 
                                                                value={fund.attributes.qty} 
                                                                decimalScale={fund.included.fund.decimals} 
                                                                allowLeadingZeros thousandSeparator="." 
                                                                decimalSeparator="," 
                                                            /> 
                                                        </td>
                                                        <td className="text-center">
                                                            {fund.attributes.fund_payment_status_id === 1 && <span className="badge bg-label-success me-1">Pendientes</span>}
                                                            {fund.attributes.fund_payment_status_id === 2 && <span className="badge bg-label-warning me-1">Pagado</span>}
                                                            {fund.attributes.fund_payment_status_id === 3 && <span className="badge bg-label-danger me-1">Cancalado</span>}
                                                        </td>
                                                        <td className="text-end">
                                                            <div className="dropdown">
                                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doView(fund) } }>Ver Operación</a>
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doPay(fund) } }>Pagar</a>
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doCancel(fund) } }>Anular</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </TabPanel>
                <TabPanel value={1}>
                    <Grid xs={12}>
                        <div className="card h-100">
                            <div className="card-body p-0">
                                <div className="table-responsive" style={{"min-height": "300px"}}>
                                <table className="table table-striped table-hover">
                                        <thead>
                                            <tr className="text-nowrap">
                                                <th>#</th>
                                                <th>Comitente</th>
                                                <th>Fecha Operación</th>
                                                <th>Fecha Pago</th>
                                                <th>Moneda</th>
                                                <th className="text-end">Monto</th>
                                                <th className="text-center">Estado</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {pendingListFunds.map(fund => {
                                                return (
                                                    <tr key={fund.id}>  
                                                        <td><span className="badge bg-label-dark">{fund.id}</span></td>
                                                        <td>
                                                            <span className="fw-medium">
                                                                {fund.included.accountModel.name.length > 45 ?
                                                                    `${fund.included.accountModel.name.substring(0, 45)}...` : fund.included.accountModel.name
                                                                }
                                                            </span>
                                                            <small class="text-muted d-block mb-1">
                                                                <span className="badge bg-label-primary me-1">{fund.attributes.account}</span>
                                                            </small>
                                                        </td>
                                                        <td>{moment(fund.attributes.date).format('DD MMM YYYY')}</td>
                                                        <td>{moment(fund.attributes.settlement_date).format('DD MMM YYYY')}</td>
                                                        <td>
                                                            {fund.included.currency.id === 1 && <span className="badge bg-label-secondary me-1">{fund.included.currency.name}</span>}
                                                            {fund.included.currency.id === 2 && <span className="badge bg-label-success me-1">{fund.included.currency.name}</span>}
                                                            {fund.included.currency.id === 3 && <span className="badge bg-label-primary me-1">{fund.included.currency.name}</span>}
                                                        </td>
                                                        <td className="text-end"> 
                                                            <span class="text-muted">{fund.included.currency.symbol} </span>
                                                            <NumericFormat 
                                                                displayType="text" 
                                                                value={fund.attributes.qty} 
                                                                decimalScale={fund.included.fund.decimals} 
                                                                allowLeadingZeros thousandSeparator="." 
                                                                decimalSeparator="," 
                                                            /> 
                                                        </td>
                                                        <td className="text-center">
                                                            {fund.attributes.fund_payment_status_id === 1 && <span className="badge bg-label-success me-1">Pendientes</span>}
                                                            {fund.attributes.fund_payment_status_id === 2 && <span className="badge bg-label-warning me-1">Pagado</span>}
                                                            {fund.attributes.fund_payment_status_id === 3 && <span className="badge bg-label-danger me-1">Cancalado</span>}
                                                        </td>
                                                        <td className="text-end">
                                                            <div className="dropdown">
                                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doView(fund) } }>Ver Operación</a>
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doPay(fund) } }>Pagar</a>
                                                                    <a className="dropdown-item" href="javascript:void(0);" onClick={() => {  doCancel(fund) } }>Anular</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Grid>             
                </TabPanel>
            </Tabs>

            { fundSelected !== '' ?
                <ModalViewFund 
                    handleOpen={setModalViewOpen} 
                    isOpen={modalViewOpen} 
                    selected={fundSelected}
                    fundId={fundSelected.id}
                />
            :
                null
            }


        </div>        
    );

}

export default Home;