import React, {useState, useEffect} from "react";


function DocumentPreviewModal( {document}) {
        
    return (
        <div className="modal fade" id="documentPreviewModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel1">Vista previa</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            { document !== null ?
                                <span>
                                    <center>
                                        <img src="toronto-logo.jpg" alt="toronto trust logo" className="img-fluid mb-3" style={{width : "30%"}}/>
                                        <h4><strong>{document.attributes.name}</strong></h4>
                                    </center>
                                    <div dangerouslySetInnerHTML={{__html: document.attributes.statement} } />
                                </span>
                                
                            :
                                <div />
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal"> Cerrar </button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default DocumentPreviewModal;