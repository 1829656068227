import { useContext } from 'react';
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom"
import {MyContext} from "../context/MyContext";

function Toolbar() {

  let userModel = ''
  const navigate = useNavigate();
  const { toggleMenu, setToggleMenu } = useContext(MyContext);

  const userProfile = localStorage.getItem("userProfile");
  if (userProfile) {
     userModel = JSON.parse(userProfile);
  }else{
      window.location.reload();
  }
  
  const doLogout = async () => {
    localStorage.removeItem("userProfile");
    navigate('/')
    window.location.reload();
  }

  const doMenu = async () => { setToggleMenu(!toggleMenu) }

    return (  
       <nav className="layout-navbar container-fluid navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a className="nav-item nav-link px-0 me-xl-4" href="">
                <i className="bx bx-menu bx-sm" />
            </a>
        </div>
        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
          {/* Search */}
          {/* <div className="navbar-nav align-items-center">
            <div className="nav-item d-flex align-items-center">
              <i className="bx bx-search fs-4 lh-0" />
              <input type="text" className="form-control border-0 shadow-none" placeholder="Search..." aria-label="Search..." />
            </div>
          </div> */}
          {/* /Search */}
          <div className="navbar-nav align-items-center">
            <div className="nav-item d-flex align-items-center">
              <a href="#" onClick={doMenu}>
                <i class="bx bx-menu bx-sm"></i>
              </a>
              <img src="alchemy-logo.png" alt="alchemy valores logo" className="img-fluid" style={{width: '50%'}}/>
            </div>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* User */}
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a className="nav-link dropdown-toggle hide-arrow" href="/" data-bs-toggle="dropdown">
                <div className="avatar avatar-online">
                  <img src={userModel.photo} alt="avatar" className="w-px-40 h-auto rounded-circle" />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a className="dropdown-item" href="#">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img src={userModel.photo} alt="avatar"  className="w-px-40 h-auto rounded-circle" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-semibold d-block">{userModel.fullName}</span>
                        <small className="text-muted">{userModel.role}</small>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                <li>
                  <Link to='/changepass' className="dropdown-item">
                    <i className="bx bx-cog me-2" />
                    <span className="align-middle">Cambiar Contraseña</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                <li>
                  <Link to="/" className="dropdown-item" onClick={doLogout}>
                    <i className="bx bx-power-off me-2" />
                    <span className="align-middle">Cerrar Sesión</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/*/ User */}
          </ul>
        </div>
      </nav>

    );
}

export default Toolbar;