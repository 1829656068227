import React, {useState} from "react";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
import {useNavigate} from "react-router-dom"
import userService from "../../services/userService";


function Changepass() {
    
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const [actualpassword, setActualpassword] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [newrepassword, setNewrepassword] = useState('');
   
    
    const doChange = async () => {
        if(validateForm()){
            try {  
                setSending(true)
                const model = {  
                    password_old: actualpassword,
                    password: newpassword,
                    password_confirmation: newrepassword,
                }
                const response = await userService.setNewPassword(model)
                toast.success('Contraseña actualizada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(-1)
                
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function validateForm (){
        let rta = true;
        if(actualpassword === '' || actualpassword === undefined  || actualpassword === null){ return false; } 
        if(newpassword === '' || newpassword === undefined  || newpassword === null){ return false; } 
        if(newrepassword === '' || newrepassword === undefined  || newrepassword === null){ return false; } 
        if(newrepassword !== newpassword){ return false; }
        return rta;
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row mb-3">
                <div className="col">
                    <h2> Cambiar Contraseña </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-xl">
                    <div className="card">
                        <form className="card-body">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-actual">Contraseña Actual<span style={{color:'red'}}>*</span></label>
                                <input type="password" className="form-control" id="basic-default-actual" placeholder="Ingrese Contraseña" onChange={(e)=> setActualpassword(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-new">Nueva Contraseña <span style={{color:'red'}}>*</span></label>
                                <input type="password" className="form-control" id="basic-default-new" placeholder="Ingrese Contraseña" onChange={(e)=> setNewpassword(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-repeat">Repita Nueva Contraseña <span style={{color:'red'}}>*</span></label>
                                <input type="password" className="form-control" id="basic-default-repeat" placeholder="Confirme Contraseña" onChange={(e)=> setNewrepassword(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <small style={{color:'red'}}>* obligatorios</small>
                            </div>
                            <div className="d-grid gap-2 mb-3">
                                { sending ? 
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border text-info" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                : 
                                    <button type="button" className="btn btn-bl btn-info" onClick={doChange}>Cambiar Contraseña</button>
                                }
                            </div>
                        </form>
                        
                    </div>
                </div>
                <div className="col-xl"></div>

            </div>

        </div>        
    );

}

export default Changepass;