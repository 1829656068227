import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
// import { NumericFormat } from 'react-number-format';
import fundService from "../../../services/fundService";


function ModalNewFund( {handleOpen, isOpen, listTerm}) {

    const [sending, setSending] = useState(false)
    const [newModel, setNewModel] = useState({
        name: '',
        stock_box_code_interface: '',
        subscription_term_id: '1',
        rescue_term_id: '1',
        currency_id: '1',
        limit_date: '',
        unit_price: '',
        decimals: 2,
    })

    const doSave = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                const response = await fundService.setFund(newModel)
                toast.success('Fondo creado!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleOpen(false)
                setNewModel({
                    name: '',
                    stock_box_code_interface: '',
                    subscription_term_id: '1',
                    rescue_term_id: '1',
                    currency_id: '1',
                    limit_date: '',
                    unit_price: '',
                    decimals: 2,
                })
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        // navigate('/')
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function validateNewForm (){
        let rta = true;
        if(newModel.name === '' || newModel.name === undefined  || newModel.name === null){ return false; } 
        if(newModel.stock_box_code_interface === '' || newModel.stock_box_code_interface === undefined  || newModel.stock_box_code_interface === null){ return false; } 
        if(newModel.currency_id === '' || newModel.currency_id === undefined  || newModel.currency_id === null){ return false; } 
        if(newModel.subscription_term_id === '' || newModel.subscription_term_id === undefined  || newModel.subscription_term_id === null){ return false; } 
        if(newModel.rescue_term_id === '' || newModel.rescue_term_id === undefined  || newModel.rescue_term_id === null){ return false; } 
        if(newModel.limit_date === '' || newModel.limit_date === undefined  || newModel.limit_date === null){ return false; } 
        if(newModel.decimals === '' || newModel.decimals === undefined  || newModel.decimals === null){ return false; } 
        return rta;
    }



    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Nuevo Fondo Comun de Inversión</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-nombre-selected">Nombre <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-nombre-selected" placeholder="Ingrese Nombre"                                
                                    value={newModel.name}  onChange={(e)=> setNewModel({...newModel, name: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Código de CV <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder="Ingrese Código de CV "                                
                                    value={newModel.stock_box_code_interface}  onChange={(e)=> setNewModel({...newModel, stock_box_code_interface: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-moneda-selected">Moneda <span style={{color:'red'}}>*</span></label>
                                <select className="form-select" id="basic-default-moneda-selected" 
                                    defaultValue={'1'} 
                                    onChange={(e)=> setNewModel({...newModel, currency_id: e.target.value}) }
                                >
                                    <option value="1">Pesos</option>
                                    <option value="2">Dolar Cable</option>
                                    <option value="3">Dolar MEP</option>
                                </select> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-suscrip-selected">Plazo Suscripción <span style={{color:'red'}}>*</span></label>
                                <select className="form-select" id="basic-default-suscrip-selected" 
                                    defaultValue={'1'} 
                                    onChange={(e)=> setNewModel({...newModel, subscription_term_id: e.target.value}) }
                                >
                                    {listTerm
                                        .map( (term) => {
                                            return <option value={term.id} key={term.id}>{term.attributes.name} </option> 
                                        }
                                    )}
                                </select> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-rescate-selected">Plazo Rescate <span style={{color:'red'}}>*</span></label>
                                <select className="form-select" id="basic-default-rescate-selected" 
                                    defaultValue={'1'} 
                                    onChange={(e)=> setNewModel({...newModel, rescue_term_id: e.target.value}) }
                                >
                                    {listTerm
                                        .map( (term) => {
                                            return <option value={term.id} key={term.id}>{term.attributes.name} </option> 
                                        }
                                    )}
                                </select> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-valor-selected">Valor de Cuotaparte</label>
                                {/* <NumericFormat 
                                    displayType="input" 
                                    value={newModel.unit_price} 
                                    decimalScale={8} 
                                    allowLeadingZeros 
                                    thousandSeparator="." 
                                    decimalSeparator="," 
                                    className="form-control" id="basic-default-valor-selected" placeholder="Ingrese Valor de Cuotaparte"   
                                    onChange={(e)=> setNewModel({...newModel, unit_price: e.target.value})  }
                                /> */}
                                <input type="text" className="form-control" id="basic-default-valor-selected" placeholder="Ingrese Valor de Cuotaparte"                               
                                   value={newModel.unit_price}  onChange={(e)=> setNewModel({...newModel, unit_price: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-decimales-selected">Decimales <span style={{color:'red'}}>*</span></label>
                                <input type="number" min="1" max="8" className="form-control" id="basic-default-decimales-selected" placeholder="Ingrese Decimales"                              
                                   value={newModel.decimals}  onChange={(e)=> setNewModel({...newModel, decimals: e.target.value}) }
                                />
                                <div class="form-text"><small>Como máximo 8 decimales</small></div>
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-hora-selected">Hora Limite <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-hora-selected" placeholder="Ingrese Hora Limite"                              
                                   value={newModel.limit_date}  onChange={(e)=> setNewModel({...newModel, limit_date: e.target.value}) }
                                />
                                <div class="form-text"><small>Formato HH24:MM</small></div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" 
                        onClick={() => doSave() }
                        disabled={sending}
                    >
                        Guardar
                    </button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default ModalNewFund;