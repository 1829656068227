import React, {useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userService from "../../services/userService";
import roleService from "../../services/roleService";
import {useNavigate} from "react-router-dom"
import UserNewModal from "./userNewModal";
import UserEditModal from "./userEditModal";


function Users() {
    const navigate = useNavigate();
    const initialized = useRef(false)
    const [listUsers, setListUsers] = useState([])
    const [listUsersLinks, setListUsersLinks] = useState([])
    const [listUsersMeta, setListUsersMeta] = useState([])
    const [listRoles, setListRoles] = useState([])
    const [userSelected, setUserSelected] = useState('')
    const [sending, setSending] = useState(false)
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad()
        }
    }, []);

    const doLoad = async () => {
        try {  
            setSending(true)
            const response = await userService.getUsers()
            setListUsers(response.data)
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            const responseRole = await roleService.getRoles()
            setListRoles(responseRole.data)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                // let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                      })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
                
              }
              setSending(false)
        }
    }

    const doSearch = async () => {
        try {  
            setSending(true)
            const response = await userService.getUserSearch(email,username,role)
            setListUsers(response.data)
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doDelete = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere eliminar el usuario seleccionado ?",
            icon: "warning",
            showCancelButton: true,
            // cancelButtonColor: '#efefef',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#f13939',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await userService.deleteUser(obj.id)
                    toast.success('Usuario eliminado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    const doPassword = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Está seguro que quiere resetear la contraseña del usuario ?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#71dd37',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {
             try {  
                setSending(true)
                const model = {
                    name: obj.attributes.name,
                    last_name: obj.attributes.last_name,
                    email: obj.attributes.email,
                    role: obj.included.roles[0].name,
                    change_password: 1,
                    password_generate: 1,
                    password: ''
                }
                
                const response = await userService.putUser(obj.id, model)
                toast.success('Contraseña actualizada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
                setSending(false)
            }
            }
          })
    }

    const doPagination = async () => {
        try {  
            setSending(true)
            const response = await userService.getUserSearchPagination(listUsersLinks.next)
            response.data.forEach( data => listUsers.push(data) )
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }
   
    return (
       
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row mb-3">
                <div className="col">
                    <h2>Usuarios  { listUsersMeta.total > 0 && <span className="fontMedium">({listUsersMeta.total})</span> } </h2>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#newModal">
                            <i className="tf-icons bx bx-plus" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>
        

            <div className="card">
                
                <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nombre / Usuario</th>
                                {/* <th></th> */}
                                <th>Perfil</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listUsers.map(user => {
                                return (
                                    <tr key={user.id}>
                                        <td>
                                            <div class="d-flex justify-content-start align-items-center user-name">
                                                <div class="avatar-wrapper">
                                                    <div class="avatar avatar-sm me-3">
                                                        <img src={user.attributes.profile_photo_url} alt="avatar" className="w-px-40 h-auto rounded-circle" />
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <a href="app-user-view-account.html" class="text-body text-truncate">
                                                        <span class="fw-medium">{user.attributes.full_name}</span>
                                                    </a>
                                                    <small class="text-muted">{user.attributes.email}</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td> {user.included.roles.map((role) => role.name)}</td>
                                        <td>
                                            {user.attributes.email_verified_at === '' && <span className="badge bg-label-warning me-1">Pendiente</span>}
                                            {user.attributes.email_verified_at !== '' && <span className="badge bg-label-success me-1">Verificado</span>}
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                <i className="bx bx-dots-vertical-rounded" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => {  setUserSelected(user) } }><i className="bx bx-edit-alt me-1" /> Editar</a>
                                                <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doPassword(user) } }><i className="bx bx-refresh me-1" /> Contraseña</a>
                                                <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doDelete(user) } }>
                                                    <i className="bx bx-trash me-1" /> Eliminar
                                                </a>
                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>
            </div>
                { listUsersLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    <span></span>
                }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Nombre</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Nombre del usuario" onChange={(e)=> setUsername(e.target.value)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-email">Email</label>
                                <div className="input-group input-group-merge">
                                    <input type="email" id="basic-default-email" className="form-control" placeholder="Ingrese email del usuario" aria-label="john.doe" aria-describedby="basic-default-email2" 
                                        onChange={(e)=> setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Perfil</label>
                                <select className="form-select" id="roleControlSelect" aria-label=""  onChange={(e)=> setRole(e.target.value)}>
                                    <option selected value={''}>Todos</option>
                                    {listRoles.map( (role) => 
                                        <option value={role.id}>{role.attributes.name}</option> )
                                    }
                                </select> 
                            </div>
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* MODAL */}
            <UserNewModal listRoles={listRoles}/>
            <UserEditModal listRoles={listRoles} userInfo={userSelected}/> 

            <ToastContainer></ToastContainer>
        </div>        
         
    );

}

export default Users;