import React, {useState} from "react";
import Swal from "sweetalert2";
import axios from 'axios'
import authService from "../../services/authService";
// import {useNavigate} from "react-router-dom"
import { Link } from "react-router-dom";


function Login() {
  // const navigate = useNavigate();
  const [sending, setSending] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const doLogin = async () => {
    setSending(true)
    if(email === '' || password === ''){
      Swal.fire({
        title: 'Ooops?',
        text: 'Debe completar el usuario y contraseña para poder avanzar!',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#f13939'
      })
    }else{
      try {
        const response = await authService.login(email, password, '')
        const model = {
          id: response.data[0].user.id,
          fullName: response.data[0].user.full_name,
          emial: response.data[0].user.email,
          token: response.data[0].token,
          photo: response.data[0].user.profile_photo_url,
          role: response.data[0].user.roles[0].name
        }
        localStorage.setItem('userProfile', JSON.stringify(model) );
        window.location.reload();
      } catch (e) {
        
        if (axios.isAxiosError(e)) {
          const statusCode = e.response?.status
          const error = e.response?.data.errors;
          let message = '';
          
          if(statusCode && statusCode !== 401){
            message = error[0].detail[0]  
          }else{
            message = error[0].detail
          }
          Swal.fire({
            title: 'ERROR',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f13939'
          })
        }
      }
      
    }
    setSending(false)
    
  }
    return (
      <div>
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner">
              {/* Register */}
              <div className="card">
                <div className="card-body">
                  {/* Logo */}
                  <div className="app-brand justify-content-center">
                    <a href="/" className="app-brand-link gap-2">
                      <img src="alchemy-logo.png" alt="alchemy valores logo" className="img-fluid" style={{width: '100%'}}/>
                    </a>
                  </div>
                  {/* /Logo */}
                  <h4 className="mb-2">Bienvenidos! 👋</h4>
                  <p className="mb-4">Por favor ingresa a tu cuenta</p>
                  <form onSubmit={doLogin}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="text" className="form-control" id="email" name="email-username" placeholder="Ingrese email" autoFocus onChange={(e)=> setEmail(e.target.value)}/>
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">Contraseña</label>
                        <Link to={'/register'}>
                          <small>Olvide Contraseña?</small>
                        </Link>
                      </div>
                      <div className="input-group input-group-merge">
                        <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" onChange={(e)=> setPassword(e.target.value)}/>
                        {/* <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span> */}
                      </div>
                    </div>
                    <div className="mb-3">
                    { sending ? 
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-info" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    : 
                      <button className="btn btn-info d-grid w-100" type="submit" onClick={doLogin}>Iniciar</button> 
                    }
                    </div>
                  </form>
                </div>
              </div>
              {/* /Register */}
            </div>
          </div>
        </div>
        <footer style={{textAlign: 'right', marginRight: 20,fontSize: 'small', marginTop: '-25px'}}>
          Power by <a href="https://globemind.com/" target="_blank" style={{color: '#396bad'}} rel="noreferrer">GlobeMind</a>
        </footer>
      </div>

      

    );
  }
  
  export default Login;