import React, {useContext} from "react";
import { Outlet } from "react-router-dom"; 
import { ToastContainer } from 'react-toastify';
import Menu from "../../components/menu"
import Toolbar from "../../components/toolbar";
import Footer from "../../components/footer";
import {MyContext} from "../../context/MyContext";


function Layout() {

    const { toggleMenu, setToggleMenu } = useContext(MyContext);

    return (
        <div className="layout-wrapper layout-content-navbar layout-without-menu">
            <div className="layout-container" >

                <div className={(!toggleMenu ? 'layout-page-no-menu layout-page' : "layout-page")}>
                {/* <div className={"layout-page" + (toggleMenu ? 'layout-page-no-menu' : "payout-page")}> */}

                    <Toolbar/>
                    <Menu />
                    <div className="content-wrapper layout-menu-fixed">
                        <Outlet />
                        <Footer />
                    </div>
                </div>

            </div>
            <ToastContainer></ToastContainer>
        </div>

    );

}

export default Layout;