import api from "../apiapp";


const listService = {
    getTermList: async function () {
        const response = await api.request({
            url: 'settlement-terms/',
            method: "GET",
        })
        
        return response.data
    },
    getTypeInvestmentsList: async function () {
        const response = await api.request({
            url: 'type-of-investments/',
            method: "GET",
        })
        
        return response.data
    },
    getCurrenciesList: async function () {
        const response = await api.request({
            url: 'currencies/',
            method: "GET",
        })
        
        return response.data
    },
   
 
}
  
export default listService;
