import { useState, React } from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from "./screens/login";
import Recovery from './screens/recovery';
import Home from './screens/home';
import Layout from './screens/layout';
import Users from './screens/users';
import Changepass from './screens/changepass';
import Notifications from './screens/ddjj';
import Documents from './screens/ddjj/documents';
import Funds from './screens/setting/funds';
import ReportFundsBackoffice from './screens/reports/report-fci-backoffice';

import { MyContext } from "./context/MyContext";


function App() {
  const userProfile = localStorage.getItem("userProfile");
  const [toggleMenu, setToggleMenu] = useState(true);
  // if (!userProfile) { navigate("/") }
  return (
    <div className="App">
      { !userProfile ? 
        <Router>
          <Routes>
            <Route path='/' element={<Login/>}></Route>
            <Route path='/register' element={<Recovery/>}></Route>
          </Routes>
        </Router>
      :
      <MyContext.Provider value={{ toggleMenu, setToggleMenu }}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="users" element={<Users />} />
              <Route path="changepass" element={<Changepass />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="documents" element={<Documents />} />
              <Route path="funds" element={<Funds />} />
              {/* REPORTS */}
              <Route path="reportFCIBackoffice" element={<ReportFundsBackoffice />} />
            </Route>
          </Routes>
        </Router>
      </MyContext.Provider>
      }
    </div>
  );
}

export default App;
