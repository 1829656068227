import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import {MyContext} from "../context/MyContext";


function Menu() {

  const [active, setActive] = useState('m1');
  const [open, setOpen] = useState(false);
  const [subactive, setSubActive] = useState(null);
  const [selected, setSelected] = useState(null);
  const { toggleMenu, setToggleMenu } = useContext(MyContext);

  function setSubmenu (parent, id)  {
    setSubActive(id)
    setActive(parent)
    setSelected(parent)
    setOpen(true)
  }

    return (
      <span>
      {toggleMenu &&
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="menu-inner-shadow" />
            <ul className="menu-inner py-1">

              <li  id="m1" 
                onClick={() => setActive('m1') }
                className={`menu-item ${active === 'm1' && "active"}`}
              >
                <Link to="/" className="menu-link">
                  <i className="menu-icon tf-icons bx bx-home-circle" />
                  <div data-i18n="Analytics">Inicio</div>
                </Link>
              </li>

              {/* <li id="m2"
                onClick={() =>  { setOpen(!open); setSelected('m2');}}
                className={`menu-item ${active === 'm2' && " active open"}  ${(selected === 'm2') && "open"} `}
              >
                <Link href="javascript:void(0);" className="menu-link menu-toggle ">
                  <i className="menu-icon tf-icons bx bx-file" /> 
                  <div data-i18n="Layouts">DD.JJ.</div>
                </Link>
                <ul className="menu-sub">
                  <li id="m2-1"
                    onClick={() => setSubmenu('m2','m2-1')}
                    className={`menu-item ${subactive === 'm2-1' && "active"}`}
                  >
                    <Link to="/notifications" className="menu-link ">
                      <div data-i18n="Without menu ">Notificaciones</div>
                    </Link>
                  </li>
                  <li id="m2-1"
                    onClick={() => setSubmenu('m2','m2-2')}
                    className={`menu-item ${subactive === 'm2-2' && "active"}`}
                  >
                    <Link to="/documents" className="menu-link">
                      <div data-i18n="Without navbar">Documentos</div>
                    </Link>
                  </li>
                </ul>
              </li> */}
              
              <li class="menu-header small text-uppercase"><span class="menu-header-text">Fondos Comunes Inversión</span></li>
              <li id="m3" 
                onClick={() => {setActive('m3'); setSelected('m3');}}
                className={`menu-item ${active === 'm3' && "active"}`}
              >
                <Link to="/reportFCIBackoffice" className="menu-link">
                  <i className="menu-icon tf-icons bx bx-file" /> 
                  <div data-i18n="Analytics">Reporte Operaciones</div>
                </Link>
              </li>

              <li class="menu-header small text-uppercase"><span class="menu-header-text"></span></li>
             

              <li id="m4"
                onClick={() => { setOpen(!open); setSelected('m4');} }
                className={`menu-item ${active === 'm4' && " active open"} ${(selected === 'm4') && "open"} `}
              >
                <Link href="javascript:void(0);" className="menu-link menu-toggle ">
                  <i className="menu-icon tf-icons bx bx-cog" /> 
                  <div data-i18n="Layouts">Configuración</div>
                </Link>
                <ul className="menu-sub">
                  <li id="m4-1"
                    onClick={() => setSubmenu('m4','m4-1')}
                    className={`menu-item ${subactive === 'm4-1' && "active"}`}
                  >
                    <Link to="/funds" className="menu-link ">
                      <div data-i18n="Without menu ">Fondos Comunes Inversión</div>
                    </Link>
                  </li>
                </ul>
              </li>
            
            </ul>
            
        </aside>
      }
      </span>
  
    );
  }
  
  export default Menu;
  